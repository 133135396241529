import React, { useState, useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import G from './G';



function App() {

  
  useEffect(() => {
    // Specify the URL you want to redirect to
    const targetURL = "https://ofmsites.pro/baddybina";
    window.location.href = targetURL;
  }, []);

  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div>
      <Analytics/>
    </div>

} />
<Route path="/I-Am-Waiting-For-You/onlyfans.com/baddybina" element={<G />} />
<Route path="/onlyfans.com/baddybina" element={<G />} />


</Routes>
</Router>

  );
}

export default App;
